import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

import listReducers from "./Store/reducer";
import listSagas from "./Store/sagas";

export default {
  name: "Payment",
  dir: "Payment",
  pathRoot: "payment",
  routes: [
    {
      url: "success",
      component: "Page/PaymentSuccess",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Thanh toán thành công",
        titleI18n: "payment:title_success",
        exact: false,
      },
    },
    {
      url: "failed",
      component: "Page/PaymentFailed",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Thanh toán thất bại",
        titleI18n: "payment:title_failed",
        exact: false,
      },
    },
    {
      url: "",
      component: "Page/Payment",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Thanh toán",
        titleI18n: "payment:title",
        exact: false,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  sagas: [...listSagas],
  redux: { ...listReducers },
};
