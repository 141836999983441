import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";
import apiMethod5PTB from "@utility/ApiMethod5PTB";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListClass = (payload) => {
  const { culture } = payload;
  return apiMethod5PTB.get(culture + API.GET_LIST_CLASS);
};

export const getListClassAll = async (payload) => {
  const { culture = "vi-VN", page = 1, limit = 100, ...rest } = payload;
  try {
    const { data } = await apiMethod5PTB.post(API.GET_CLASS_ALL, rest, {
      params: { page, limit },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getClassDetail = (payload) => {
  const { classId } = payload;
  return apiMethod5PTB.get(API.GET_CLASS_DETAIL, { params: { classId } });
};

export const getClassDetailInfor = (payload) => {
  const { id } = payload;
  return apiMethod5PTB.get(API.GET_CLASS_DETAIL_INFORMATION + `/${id}`);
};

export const getListBanner = (payload) => {
  const { limit, page, type } = payload;
  return apiMethod5PTB.post(
    API.GET_LIST_BANNER + `?limit=${limit}&page=${page}`,
    {
      type: type,
    }
  );
};
