export default {
  GET_BANNER: "WebsiteSTNHD/TrangChu",

  // eclass
  GET_LIST_CLASS: "/EClass/listClient",

  // class detail
  GET_CLASS_DETAIL: "/MstTrialLearn/list-client",
  GET_CLASS_ALL: "/MstTrialLearn/list",

  // Detaill infor class
  GET_CLASS_DETAIL_INFORMATION: "/MstTrialLearn/detail",

  // list banner
  GET_LIST_BANNER: "/MstUploadimage/list-landingPage",
};
