export const ENV_ENVIRONMENT_BASE_URL_API_5PTB =
  "https://api5ptbver2.5ptb.com/api";
export const ENV_ENVIRONMENT_BASE_URL_V2_API =
  "https://api5ptbver2.5ptb.com/api";
export const ENV_ENVIRONMENT_BASE_URL_API =
  "https://apiptb.5ptb.com/api";
export const ENV_ENVIRONMENT_BASE_URL_API_DEV =
  "https://apiptb.5ptb.com/api";
export const ENV_ENVIRONMENT_BASE_URL_API_V2 =
  "https://api5ptbver2.5ptb.com/api";
const ENV_ENVIRONMENT_BASE_URL_API_FIRE_BASE =
  "https://us-central1-tamtriluc-76b54.cloudfunctions.net/api";
const ENV_ENVIRONMENT_FIRE_BASE_SECURITY = {
  secretApp: "tamtriluc-firebase",
  appSecretRegisterApp: "666-secret-2-register-3-@@app!",
  email: "tamtriluc-firebase@admin.admin",
  password: "tamtrilucgenerate092123222",
};
const ENV_ENVIRONMENT_firebaseConfig = {
  apiKey: "AIzaSyBLHUn_Q1PHZc8WsKuc9y45Bz5_iT--2ao",
  authDomain: "tamtriluc-76b54.firebaseapp.com",
  databaseURL:
    "https://tamtriluc-76b54-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tamtriluc-76b54",
  storageBucket: "tamtriluc-76b54.appspot.com",
  messagingSenderId: "465366344602",
  appId: "1:465366344602:web:c83ab3e6f4217ee2d6d023",
  measurementId: "G-Y2EFTL9G4H",
};
const ENV_ENVIRONMENT_awsKeys = {
  accessKeyID: "RlK3uGBY38mfev2lFGGv",
  secretKeyID: "dZnBmWg2Qh2UPA5OGPUfRfrGjIvKsokVlh7oXCVZ",
  endpoint: "https://s3.5ptb.com",
  bucket: "5ptb",
};

export const KEY_MAP = "AIzaSyAP2EDHv1qETgUfjOys1ov_PlAAUt-H05Y";
export const ENVIRONMENT_firebaseConfig = ENV_ENVIRONMENT_firebaseConfig;
export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;

export const ENVIRONMENT_BASE_URL_API_FIRE_BASE =
  ENV_ENVIRONMENT_BASE_URL_API_FIRE_BASE;
export const ENVIRONMENT_FIRE_BASE_SECURITY =
  ENV_ENVIRONMENT_FIRE_BASE_SECURITY;
