import API from "../../configs/api";
import apiMethod from "@utility/ApiMethodV2";

export const getCourseInfo = ({ payload, codeLanguage }) => {
  return apiMethod.post(codeLanguage + API.INVOICE_INFO, {
    userId: payload.userId,
    itemCode: payload.itemCode,
  });
};
export const updateInvoice = async ({ body, codeLanguage }) => {
  try {
    const { data } = await apiMethod.post(
      codeLanguage + API.UPDATE_INVOICE,
      body
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
